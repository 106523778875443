export default {
    tableFields: [
      {
        name: '__component:badge-column',
        title: '',
        dataClass: 'text-center',
        width: '4%'
      },
      {
        name: 'station.name',
        title:'Estación',
      //  sortField: 'codektag',
       
      },
      {
        name: 'device.name',
        title:'Dispositivo',
      //  sortField: 'name',       
      },
      {
        name: 'standard_credit',
        title:'Monto',
      //  sortField: 'credit',       
      },
     /* {
        name: 'virtual_credit',
        title:'Virtual',
      //  sortField: 'credit',       
      },*/
    /*  {
        name:       '__slot:status',
        title:      'Estatus',
        titleClass: "center aligned",
        dataClass:  "center aligned",       
      },*/
      {
        name: 'timestamp',
        title:'Fecha',
       // sortField: 'virtualcredit',       
      },
     
      {
        name:       '__slot:active',
        title:      'Active',
        titleClass: "center aligned",
        dataClass:  "center aligned",
        width:      "20%",
      }
    ],
   
  }
  