export default {
    tableFields: [
    /*  {
        name: '__component:badge-column',
        title: '',
        dataClass: 'text-center',
        width: '4%'
      },*/
      {
        name: 'standard_credit',
        title:'Crédito Standard',
        sortField: 'standard_credit',
       
      },
      {
        name: 'virtual_credit',
        title:'Crédito Virtual',
        sortField: 'virtual_credit',       
      },
      {
        name: 'timestamp_formated',
        title:'Fecha Hora:Minuto:Segundo',
        sortField: 'timestamp_formated',
       
      },
    
    ],
   
  }
  